export const links = [
	// {
	// 	to: '/careers',
	// 	label: 'Careers',
	// 	children: [
	// 		{
	// 			to: '/careers/data-scientist',
	// 			label: 'Data scientist',
	// 		},
	// 	],
	// },
];

export const appLinks = [{ to: '/', label: 'Zoe' }];
